export const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

export const formatPayload = (data) => ({
  fields: Object.keys(data).map((key) => ({
    name: key,
    value: data[key],
  })),
})

export const encodeWithFile = (data) => {
  return Object.keys(data).reduce((formData, key) => {
    formData.append(key, data[key])
    return formData
  }, new FormData())
}

import Lottie from 'lottie-web'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { StyleType } from '../utils/prop-types'

const Animation = ({ data, url, options, play, style }) => {
  const divRef = useRef()
  const [animation, setAnimation] = useState(null)

  const loadAnimation = () => {
    return Lottie.loadAnimation({
      container: divRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin slice',
      },
      ...((url && { path: url }) || { animationData: data }),
      ...options,
    })
  }

  useEffect(() => {
    setAnimation(loadAnimation())
    return () => {
      if (animation) {
        animation.destroy()
        setAnimation(null)
      }
    }
  }, [])

  useEffect(() => {
    if (animation && play) animation.play()
  }, [play])

  return <div ref={divRef} css={style} />
}

Animation.defaultProps = {
  options: {},
  play: false,
}

Animation.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
  options: PropTypes.object,
  play: PropTypes.bool,
  style: StyleType,
}

export default Animation
